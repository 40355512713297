/**
 * Check in the app config if a featured is enabled for this site.
 *
 * @param {'portfolio'|'projects'|'quickscan'} feature
 */
export const useFeatureEnabled = (feature) => {
    const appConfig = useAppConfig();

    if (!appConfig?.enabledFeatures?.includes(feature)) {
        showError({ statusCode: 404, statusMessage: 'Page Not Found' });
    }
};
